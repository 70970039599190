<template>
  <v-dialog
    :value="dialog"
    fullscreen
    hide-overlay
    scrollable
    transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar
        dark
        fixed
        color="primary"
        height="56px">
        <v-btn
          href="/"
          icon
          dark
          @click.prevent.stop="$router.push('/')">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>Sharper Arrow Legal Information</v-toolbar-title>
      </v-toolbar>
      <v-card-text style="height: calc(100vh - 56px); margin-top: 56px;">
        <v-flex pt-3>
          <h2 class="headline text-xs-center pb-3">Sharper Arrow Terms and Conditions</h2>
          <ol>
            <li>
              <h4>Acceptance</h4>
              <p>By accessing or using any Sharper Arrow website and/or services, you accept and agree to be bound by (1) the terms and conditions, (2) all other Sharper Arrow policies, including but not limited to Sharper Arrow's
              privacy policy found <a href="/privacy.html">here</a>. <strong>If you do not agree to the following terms please do not use our service or click "accept"</strong>. Although we will always attempt to notify you of major changes, we reserve the
              right to modify our terms and conditions periodically without notice. From time to time you should review our most up to date version of our terms and conditions found <a href="/terms.html">here</a>. If you have any questions
              regarding our terms and conditions please feel free to email us at support@sharperarrow.com.</p>
              <p>These Terms and conditions apply to all users of the Sharper Arrow Website and Sharper Arrow mobile device apps, including users who are also contributors of audiovisual content, information, and other materials or services on the Website.</p>
              <p>BY ACCEPTING THESE TERMS AND CONDITIONS (ON BEHALF OF YOURSELF OR THE ENTITY THAT YOU REPRESENT)  YOU REPRESENT AND WARRANT THAT YOU HAVE THE RIGHT, AUTHORITY, AND CAPACITY TO ENTER INTO THESE TERMS AND CONDITIONS.</p>
            </li>
            <li>
              <h4>Third Party Links</h4>
              <p>The Sharper Arrow Website may contain links to third party websites that are not owned or controlled by Sharper Arrow. Sharper Arrow has no control over and assumes no responsibility for the content, privacy policies, or practices of any third party
              site. You are responsible for reading and agreeing to any third party terms and conditions. By using the Sharper Arrow Website, you relieve Sharper Arrow from any and all liability arising from your use of any third-party website.</p>
            </li>
            <li>
              <h4>License (Rights We Grant You)</h4>
              <p>Subject to all terms and conditions, Sharper Arrow grants you a personal, non-exclusive, non-transferable, limited and revocable license to use the Services from the Effective Date for the Subscription Period. This license is personal to
              you and may not be assigned or sublicensed to anyone else without the written consent of Sharper Arrow. By using our services you agree to NOT (1) create more than one account, (2) buy, sell, license or rent your Sharper Arrow account, or (3) share
              Sharper Arrow account password.</p>
              <p>Any software Sharper Arrow provides you may automatically download and install upgrades, or other new features. You may be able to adjust these features through your device settings.</p>
              <p>You are responsible for all mobile charges incurred through the use of our services. If you are unsure of what these charges may be, please ask your service provider before using our services.</p>
            </li>
            <li>
              <h4>Content of Others</h4>
              <p>Most of the content through our service is produced by users and third parties. The content is the sole responsibility of the submitter. Although Sharper Arrow reserves the right to review and remove all content that is sent through our
              services, we do not necessarily review it all. Therefore Sharper Arrow does not take any responsibility for any content that users provide through the service. Sharper Arrow does not endorse any User Submissions or any opinion expressed therein and
              by using our service you agree to waive any legal or equitable rights you have against Sharper Arrow with respect thereto.</p>
            </li>
            <li>
              <h4>Privacy</h4>
              <p>Your privacy is very important to us. Your privacy rights are set forth in our privacy policy, which forms part of this agreement. We recommend you read carefully before agreeing to these terms. Please review the privacy policy to
              learn how Sharper Arrow treats your personal information.</p>
            </li>
            <li>
              <h4>Security</h4>
              <p>Sharper Arrow takes your account security seriously. While we work to protect the security of your account, Sharper Arrow does not guarantee that our security measures will not be breached by unauthorized third parties. Please help Sharper Arrow keep
              your account secure by selecting a strong password and keeping it safe. Customers shall take full responsibility of usernames and passwords, and be held responsible for all uses with such username and password. Please notify us immediately
              if you believe your account has been compromised or used by an unauthorized user.</p>
            </li>
            <li>
              <h4>General Use of Service/ Fair Usage Policy</h4>
              <p>Don't misuse our service. You may use our service in a way only permitted under law. Sharper Arrow reserves the right to take necessary action to comply with the law and with judicial rulings, including, but not limited to, removing your
              Content from the Service without notice.</p>
              <ol>
                <li>
                  By accepting our terms and conditions and/or using our services you agree to NOT use our service in any manner that may:
                  <ul>
                    <li>Infringe or violate intellectual property rights.</li>
                    <li>Violate any laws or regulations.</li>
                    <li>Contain harmful, fraudulent, threatening, harassing, obscene, illegal or pornographic content.</li>
                    <li>Violate the security of a computer network.</li>
                    <li>Upload viruses or other malicious code.</li>
                    <li>Alter or modify any part of the Sharper Arrow Website, including but not limited to Sharper Arrow's Player, apps or any of its related technologies.</li>
                    <li>Probe, scan or test the vulnerability of our service.</li>
                    <li>Attempt to obtain the source code or underlying ideas or information of or relating to the Services.</li>
                    <li>Encourage any activity that may infringe on the terms of service.</li>
                  </ul>
                </li>
                <li>
                  Additionally, you may not use or launch any automated system, including without limitation, "robots", "spiders", "scrapes", or "offline reader" that accesses the Website and related apps in a manner that sends more request
                  messages to the Sharper Arrow servers in a given period of time than a human can reasonably produce in the same period by using a conventional on-line web browser. Sharper Arrow is designed for PEOPLE to send media files to other PEOPLE.
                  In order to access Sharper Arrow services you MUST be human. Any access to services by automated methods is not permitted.
                </li>
                <li>
                  You agree to not distribute in any medium any part of the Website, including but not limited to User Submissions, without Sharper Arrow's prior written authorization.
                </li>
                <li>
                  If your use of the service is deemed by us to monopolize (such as "spam" or use any form of auto responder) in a way that adversely affects the services of other users we may, at our sole discretion, terminate or suspend your account.
                </li>
              </ol>
            </li>
            <li>
              <h4>Account Term and Termination Policy</h4>
              <ol>
                <li>
                  <strong>Termination by Sharper Arrow:</strong> Sharper Arrow reserves the right to decide whether Content or a User Submission is appropriate and complies with these Terms and Conditions. Sharper Arrow may remove such User Submissions and/or terminate
                  a User's access for uploading such material in violation of these Terms of Service at any time, without prior notice and at its sole discretion. If Sharper Arrow terminates your service, you are still responsible for paying all outstanding
                  fees and charges incurred before termination.
                </li>
                <li>
                  <strong>Termination by You:</strong> You may choose to terminate your account at any time for any reason by contacting Sharper Arrow at support@sharperarrow.com. Please be aware that all fees are non-refundable and if you choose to terminate your
                  account before the end of the term, you agree to pay all fees and charges.
                </li>
              </ol>
            </li>
            <li>
              <h4>Fees and Payment</h4>
              <ol>
                <li>
                  <strong>Fees:</strong> Customer agrees to pay Sharper Arrow the amounts at the time specified in the subscription plan selected. Sharper Arrow reserves the right to change the Fees and/or billing cycles for our subscription service at any time, but
                  any such changes will only apply forthcoming. All Fees are recurring (until terminated) and charged to you regardless of whether or not you use the subscription service. If you choose to enroll in a paid Sharper Arrow subscription, you agree
                  that Sharper Arrow may automatically charge the recurring Fees to your chosen payment method at the beginning of each billing cycle.
                </li>
                <li>
                  <strong>Payment Terms:</strong> Services are billed in advance on a monthly basis. The customer agrees to pay the specified amount in the subscription plan chosen. Sharper Arrow services are non-refundable, therefore there are no refunds or
                  credits in the case of cancellation, downgrading or for partially used periods. If you choose to cancel your subscription, you must cancel prior to the renewal date in order to forgo the next billing cycle. If you fail to meet your payment
                  obligations, Sharper Arrow reserves the right to terminate your paid subscription, and charge you an overage or other fees.
                </li>
                <li>
                  <strong>Taxes:</strong> All fees and charges are expressed excluding of any transaction taxes, duties, tariffs, or similar levies(including without limitation value added, goods and services, sales tax, stamp tax). You will be responsible
                  for, and pay all taxes associated with your service to Sharper Arrow.
                </li>
              </ol>
            </li>
            <li>
              <h4>Proprietary Rights</h4>
              <ol>
                <li>
                  <strong>Services:</strong> Sharper Arrow holds exclusive and sole ownership of all right, copyright, title and interest in the Sharper Arrow website, apps and services and all software used in these services. All Sharper Arrow content is protected by
                  copyright, intellectual property rights and trademark laws. Sharper Arrow retains all ownership of the Buivid application even after installation on your mobile device. You may not copy, modify, reverse engineer, disassemble or convert any
                  material on the Sharper Arrow website or applications by any means or in any form without Sharper Arrow's prior written consent.
                </li>
                <li>
                  <strong>Customer Content:</strong> Sharper Arrow does not claim ownership of any content you post through the service. However, by using our service, you hereby grant Sharper Arrow a royalty-free, fully paid-up, irrevocable, worldwide license to
                  exploit (host, store, use, modify, adapt, distribute)  the Customer Content as necessary to provide the Services to you; subject to the privacy policy. This license is for the sole purposes of providing, promoting and improving the services.
                  You understand and agree that Sharper Arrow may monitor or review any content you post through the service. We may delete any content, in whole or in part, that is found to breach our terms and conditions.
                </li>
              </ol>
            </li>
            <li>
              <h4>Copyright</h4>
              <p>Sharper Arrow respects all intellectual property rights, and we expect our users to do the same. Sharper Arrow honors the requirements set forth in the Digital Millennium Copyright Act. In this respect, we will respond quickly to all claims of copyright
              infringement and take steps to remove any infringing content we become aware of. We will terminate any user found to repeatedly infringe on copyrights.</p>
              <p>If you believe that anything on the Sharper Arrow services infringes on a copyright you own or control please provide the following information to Sharper Arrow:</p>
              <ul>
                <li>
                  Electronic or physical signature of the person authorized to act on behalf of the owner of the copyright interest.
                </li>
                <li>
                  Identify the copyrighted work or material believed to be infringed.
                </li>
                <li>
                  Identify the material that is claimed to be infringing.
                </li>
                <li>
                  Provide contact information such as name, phone number, email address and mailing address.
                </li>
                <li>
                  Provide a personal statement that you have a good faith belief that the use of the material is not authorized by the copyright owner or controller.
                </li>
                <li>
                  Provide a statement that the information in the notification is accurate and, under penalty of perjury, that you are authorized to act on behalf of the copyright owner.
                </li>
              </ul>
              <p>
                Send your completed notice to: <br>
                Sharper Arrow Corporation <br>
                444A Avoca Drive <br>
                Green Point, NSW 2250 <br>
                Australia
              </p>
            </li>
            <li>
              <h4>Warranty Disclaimer</h4>
              <p>Sharper Arrow does not guarantee that the service is available at all times. THE SERVICE, INCLUDING, WITHOUT LIMITATION, SHARPER ARROW CONTENT, IS PROVIDED ON AN "AS IS", "AS AVAILABLE"
              AND "WITH ALL FAULTS" BASIS. YOU AGREE THAT YOUR USE OF THE SHARPER ARROW WEBSITE, APPS AND SERVICES SHALL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, SHARPER ARROW , ITS
              OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE WEBSITE AND YOUR USE THEREOF. SHARPER ARROW MAKES NO WARRANTIES OR
              REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THIS SITE'S CONTENT OR THE CONTENT OF ANY SITES LINKED TO THIS SITE AND ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY</p>
              <ol>
                <li>ERRORS, MISTAKES, OR INACCURACIES OF CONTENT,</li>
                <li>PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF OUR WEBSITE,</li>
                <li>ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN,</li>
                <li>ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM OUR WEBSITE,</li>
                <li>ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH OUR WEBSITE BY ANY THIRD PARTY, AND/OR</li>
                <li>ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SHARPER ARROW WEBSITE.</li>
              </ol>
              <p>SHARPER ARROW DOES NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SHARPER ARROW WEBSITE OR ANY HYPERLINKED WEBSITE OR FEATURED IN ANY BANNER
              OR OTHER ADVERTISING, AND SHARPER ARROW WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE
              THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.</p>
            </li>
            <li>
              <h4>Limitations of Liability</h4>
              <p>
                TO THE FULLEST EXTENT PERMITTED BY THE LAW, IN NO EVENT SHALL SHARPER ARROW, IT'S OFFICERS, DIRECTORS, EMPLOYEES OR AGENTS BE LIABLE TO YOU FOR ANY DAMAGES INCLUDING WITHOUT LIMITATIONS DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL,
                SPECIAL OR PUNITIVE LOSSES THAT MAY ARISE OUT OF OR IN RELATION TO YOUR USE OF SHARPER ARROW SERVICES. LOSS INCLUDES BUT IS NOT LIMITED TO LOSS OF PROFITS, GOODWILL, DATA, OR ANY OTHER INTANGIBLE ASSET. Sharper Arrow'S TOTAL LIABILITY
                SHALL NOT EXCEED THE TOTAL AMOUNT PAID BY YOU TO SHARPER ARROW OVER ONE MONTH PRECEDING YOUR CLAIM.
              </p>
            </li>
            <li>
              <h4>Indemnity</h4>
              <p>
                You agree to fully indemnify and hold us harmless against any expenses, costs, loss  or damage that we may suffer or incur as a result of or in connection with your use of, access to or conduct in connection with Sharper Arrow
                services, including any breach by you of the Conditions.
              </p>
            </li>
            <li>
              <h4>General Provisions</h4>
              <p>
                This agreement shall be governed by the laws of the State of Texas, United States of America. Any action or disputes regarding this agreement or your use of the Sharper Arrow services must be  commenced in the State or Federal
                courts located in Texas, United State of America. By agreeing to this statement you consent to the jurisdiction of those courts.
              </p>
            </li>
            <li>
              <h4>Arbitration, Class- Action Waiver</h4>
              <p>
                You and Sharper Arrow Corp. both agree that any disputes arising out of these terms and conditions, that may not be resolved in small claims court, will be resolved through binding, individual arbitration in the state of Texas
                or another location mutually agreeable to both parties.  All arbitrations will be initiated through the American Arbitration Association's (AAA) and be governed by the AAA Consumer Arbitration Rules. Any claims that
                involves you or Sharper Arrow's intellectual property (such as copyright, trademarks, trade names, logos, patents, and trade secrets) are exempt and do not need to be resolved through arbitration. Neither you nor Sharper Arrow may
                take part in a class action suit or class wide arbitration for any claims covered by this agreement. CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY
                OTHER CUSTOMER OR USER.
              </p>
              <p>
                You may opt out of the agreement to arbitrate. If you do so, neither you nor Sharper Arrow may force the other to arbitrate. To do so you must notify Sharper Arrow in writing, within 30 days of first becoming subject to this arbitration agreement.
              </p>
            </li>
            <li>
              <h4>Ability to Accept Terms and Conditions</h4>
              <ol>
                <li>When you register with Sharper Arrow you agree to provide Sharper Arrow with accurate, true, current, and complete information.</li>
                <li>If you are using the Services on behalf of a business or some other entity, you state that you are authorized to grant all licenses set forth in these Terms and to agree to these Terms on behalf of the business or entity.</li>
              </ol>
            </li>
          </ol>
          <h5>Definitions</h5>
          <ul>
            <li>"Authorized User" means the individual customer authorized to use the Sharper Arrow account.</li>
            <li>"Content" means all text, software, scripts, graphics, photos, sounds, music, videos, audiovisual combinations, interactive features and other materials that may be viewed on, accessed through, or contributed to the Services.</li>
            <li>"Customer Content" means Content contributed to the Services by Customer.</li>
            <li>"Effective Date" means the date in which your plan begins.</li>
            <li>"Services" means the services hosted by Sharper Arrow and provided to Customer under this Agreement</li>
            <li>"Updates" means any patch, revision, improvement or modification made to the services provided by Sharper Arrow.</li>
          </ul>
        </v-flex>
        <v-flex
          id="privacy"
          mt-5>
          <h2 class="headline text-xs-center pb-3">Sharper Arrow Privacy Policy</h2>
          <p>
            Sharper Arrow takes your privacy very seriously. Our Privacy Policy explains how we and the companies we work with handle your private information in relation to our mobile services, websites and software.
          </p>
          <ol>
            <li>
              <h4>Information We Collect</h4>
              <ol>
                <li>
                  Information you give us:
                  <ul>
                    <li>
                      Although you can receive videos through the Sharper Arrow service with no account, in order to upload and send content through the service we require you to register a Sharper Arrow account. Registering an account requires some personal
                      information such as username, email address and a password. Please be aware that other users may see your username (not your email address).
                    </li>
                  </ul>
                </li>
                <li>
                  Information we get from your use of our service:
                  <ul>
                    <li>
                      By using our service Sharper Arrow may be able to collect information about your usage such as when you use Sharper Arrow, the contacts you communicate with through the service, the Sharper Arrow videos you watch, and the frequency and size of
                      data transfers, as well as information you display or click on in Sharper Arrow (including UI elements, settings, and other information).
                    </li>
                    <li>
                      Like most online services and mobile applications we may use cookies, and other technologies such as clear gifs, and log file information to: (a) store information so that you will not have to re-enter it during your visit
                      or the next time you visit the Sharper Arrow Sites; (b) provide custom, personalized content and information; (c) monitor the effectiveness of our marketing campaigns; (d) monitor aggregate metrics such as total number of visitors,
                      pages viewed, etc.; and (e) track your entries, submissions, and status in promotions, sweepstakes, and contests. You may instruct your web browser to not accept cookies, however without cookies  you may not be able to have
                      full functionality of our site and services.
                    </li>
                    <li>
                      Sharper Arrow may also collect information on the device you're using our service on, including what kind of device you are using, what operating system you are on, device settings and crash data. To learn more about what information
                      your device makes available to us, please check the policies of your device manufacturer or software provider.
                    </li>
                  </ul>
                </li>
                <li>
                  User Content:
                  <ul>
                    <li>
                      In most cases your video content is not viewed by Sharper Arrow as it passes through our automated servers, however by using the Sharper Arrow services you agree that Sharper Arrow staff may view your content for the purposes of quality control of
                      our systems and processes, within the terms of our privacy policy and terms and conditions.
                    </li>
                    <li>
                      By using Sharper Arrow you agree that Sharper Arrow and its employees have no control over the privacy of video content past when it is uploaded to third parties on your behalf.
                    </li>
                    <li>
                      You also agree that Sharper Arrow cannot, in most cases, take down or take back video materials posted to video hosting sites on your behalf. You agree that you must work directly with your video hosting site to take down or modify
                      video content or modify the manner in which it is privately or publicly displayed.
                    </li>
                    <li>
                      You are solely responsible for the user content that you upload and publish or otherwise make available on the service. You agree that we are acting as a passive channel of your online distribution of your user content.
                    </li>
                  </ul>
                </li>
              </ol>
            </li>
            <li>
              <h4>How We Use Your Information</h4>
              <p>
                Sharper Arrow's primary goal in collecting personal information is to administer your account and provide, maintain, protect and improve our services and features. Here are some of the ways we use your information to give you a better experience:
              </p>
              <ul>
                <li>Remember your information so you do not have to re-enter it every time you access our services.</li>
                <li>Send communication, we may email you in response to support questions or share information about our services.</li>
                <li>We may also send you notifications as part of the functionality of our services.</li>
                <li>Verify your identity and prevent fraud.</li>
                <li>Develop and test new features.</li>
                <li>Fix technology problems.</li>
              </ul>
            </li>
            <li>
              <h4>Sharing of Your Information</h4>
              <ol>
                <li>
                  Third Parties: We will not misuse your information. Sharper Arrow does not transfer any of your personal information to third parties or collaborating companies for marketing purposes without your consent. We may however use your email address
                  without further consent for non marketing and administrative purposes. If you request that we share content or information with a third party you agree that we may pass forward your content to the third party to enact your sharing request.
                </li>
                <li>
                  Business Transfer: In the event of a change in control (such as a merger, acquisition, or bankruptcy) Sharper Arrow may sell or otherwise transfer assets which may include your information such as name and email address, user content and other
                  personal information collected throughout your service. You will continue to own your user content and the buyer will be obligated to honour the privacy policy.
                </li>
                <li>
                  Legal request and preventing harm: Any personal information we receive may be accessed and processed for an extended period of time when it is a subject of a legal request or obligation, or an investigation into a violation of our terms
                  of service. Sharper Arrow reserves the right to share information with organizations or individuals if we have a good faith belief that access to your personal information is necessary to:
                  <ul>
                    <li>Meet any law, legal process or enforceable government request;</li>
                    <li>Enforce the terms and conditions of Sharper Arrow;</li>
                    <li>Prevent and address fraud or any other legal activity;</li>
                    <li>Protect ourselves, you and others.</li>
                  </ul>
                </li>
              </ol>
            </li>
            <li>
              <h4>How Long We Keep Your Content</h4>
              <p>
                We keep your information only as long as it is needed for legitimate business purposes and is permitted by legal requirements. Following the termination or deactivation of your account we may retain certain data and user content for a reasonable
                amount of time for analytical purposes and record keeping.
              </p>
            </li>
            <li>
              <h4>Changes To Our Privacy Policy</h4>
              <p>
                Sharper Arrow reveres the right to modify or update this privacy policy periodically. Although we will attempt to notify you if any major changes occur you should review the policy occasionally. Your continued use of our services after any modification
                constitutes your agreement to such changes.
              </p>
            </li>
          </ol>
        </v-flex>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'Legal',
  data () {
    return {
      dialog: false
    }
  },
  mounted () {
    this.dialog = true
  },
  beforeRouteLeave (to, from, next) {
    this.dialog = false
    next()
  }
}
</script>
